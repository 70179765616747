<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :filter-names="filterNames"
      @filterOption="onFiltersChange"
    >
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="$admin.can('md-models.edit')"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$admin.can('md-models.delete')"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters> </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <create
            v-if="$admin.can('md-models.create')"
            :filter-options="options"
          />
          <edit
            v-if="$admin.can('md-models.edit') && editModal"
            :is-visible-dialog="editModal"
            :toggle-edit="toggleEditModal"
            :filter-options="options"
            :md-model="selectedRow"
          />
          <confirmation-modal
            v-if="$admin.can('md-models.delete')"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    Create: () => import("./dialogs/CreateMdModel.vue"),
    Edit: () => import("./dialogs/EditMdModel.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},
      //

      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("name"),
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("manufacturer"),
          align: "left",
          sortable: false,
          value: "md_make_name",
        },
        {
          text: this.$t("vehicle_type"),
          align: "left",
          sortable: false,
          value: "vehicle_type_name",
        },
        {
          text: this.$t("motor_type"),
          align: "left",
          sortable: false,
          value: "motor_type_name",
        },
        {
          text: this.$t("start_year"),
          align: "left",
          sortable: false,
          value: "start_year",
        },
        {
          text: this.$t("end_year"),
          align: "left",
          sortable: false,
          value: "end_year",
        },
        {
          text: this.$t("battery_slot_type"),
          align: "left",
          sortable: false,
          value: "battery_slot_type",
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "status",
        },

        ...(this.$admin.hasAccessTo("md-models.delete") ||
          this.$admin.hasAccessTo("md-models.edit")
          ? [
            {
              text: this.$t("actions"),
              align: "left",
              sortable: false,
              value: "actions",
            },
          ]
          : []),
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "mdModels/isLoadingMdModels",
      meta: "mdModels/meta",
      list: "mdModels/listMdModels",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
    filterNames() {
      return ["make", "vehicle_type", "motor_type", "battery_slot_type"];
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    const params = {
      name: "md_models",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  async mounted() {
    await this.$store.dispatch("vehicleTypes/list");
    await this.$store.dispatch("motorTypes/list");
    await this.$store.dispatch("batteryTypes/list");
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("mdModels/list", this.options).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(row) {
      await this.$store
        .dispatch("mdModels/destroy", row)
        .then(() => {
          this.$store.dispatch(
            "alerts/success",
            this.$t("deleted_successfully")
          );
          this.toggleDeleteModal("close");
          this.fetchData();
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        });
    },
  },
};
</script>